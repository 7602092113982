<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UserStyles"
};
</script>

<style>
.account-text-info {
  color: black;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.account-text-header {
  color: black;
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 0.1rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin-left: -0.5rem;
}
</style>
